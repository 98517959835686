export default {
  align: 'Alignment',
  alwaysShowTooltip: 'Always show tooltip',
  clickable: 'Clickable',
  contextMenuDisabled: 'Context menu disabled',
  disabled: 'Disabled',
  draggable: 'Draggable',
  dx: 'Horizontal offset',
  dy: 'Verticall offset',
  fill: 'Fill',
  fillModifier: 'Fill modifier',
  fillOpacity: 'Fill opacity',
  focusable: 'Focusable',
  height: 'Height',
  hidden: 'Hidden',
  horizontalCenter: 'Horizontal center',
  hoverOnFocus: 'Hover on focus',
  hoverable: 'Hoverable',
  id: 'ID',
  inert: 'Inert',
  interactionsEnabled: 'Interactions enabled',
  isActive: 'Active',
  isHover: 'Mouse over',
  marginBottom: 'Bottom margin',
  marginLeft: 'Left margin',
  marginRight: 'Right margin',
  marginTop: 'Top margin',
  maxHeight: 'Max. height',
  maxWidth: 'Max. width',
  maxX: 'Max. X',
  maxY: 'Max. Y',
  minHeight: 'Min. height',
  minWidth: 'Min. width',
  minX: 'Min X',
  minY: 'Min Y',
  nonScaling: "Don't scale",
  nonScalingStroke: "Don't scale stroke",
  opacity: 'Opacity',
  paddingBottom: 'Bottom padding',
  paddingLeft: 'Left padding',
  paddingRight: 'Right padding',
  paddingTop: 'Top padding',
  path: 'Path',
  pixelPerfect: 'Pixel perfect',
  readerChecked: 'Reader checked',
  readerControls: 'Reader controls',
  readerDescribedBy: 'Reader described by',
  readerDescription: 'Reader description',
  readerHidden: 'Reader hidden',
  readerLabelledBy: 'Reader labelled by',
  readerLive: 'Reader live',
  readerOrientation: 'Reader orientation',
  readerTitle: 'Reader title',
  readerValueNow: 'Reader value now',
  readerValueText: 'Reader value text',
  resizable: 'Resizable',
  role: 'Role',
  rotation: 'Rotation',
  scale: 'Scale',
  shapeRendering: 'Shape rendering',
  showOnInit: 'Show on init',
  showTooltipOn: 'Show tooltip on',
  stroke: 'Stroke',
  strokeDasharray: 'Stroke dash array',
  strokeDashoffset: 'Stroke dash offset',
  strokeLinecap: 'Stroke line cap',
  strokeLinejoin: 'Stroke line join',
  strokeModifier: 'Stroke modifier',
  strokeOpacity: 'Stroke opacity',
  strokeWidth: 'Stroke width',
  swipeable: 'Swipeable',
  tabindex: 'Tab index',
  togglable: 'Togglable',
  tooltipHTML: 'Tooltip HTML',
  tooltipPosition: 'Tooltip position',
  tooltipText: 'Tooltip text',
  tooltipX: 'Tooltip X',
  tooltipY: 'Tooltip Y',
  trackable: 'Trackable',
  url: 'URL',
  urlTarget: 'URL target',
  valign: 'Vertical alignment',
  verticalCenter: 'Vertical center',
  visible: 'Visible',
  wheelable: 'Wheelable',
  width: 'Width',
  x: 'X',
  y: 'Y',
  zIndex: 'Z index',
  contentAlign: 'Content alignment',
  fixedWidthGrid: 'Fixed width grid',
  fontFamily: 'Font family',
  fontSize: 'Font size',
  fontWeight: 'Font weight',
  layout: 'Layout',
  maxColumns: 'Max. number of columns',
  reverseOrder: 'Reverse order',
  setStateOnChildren: 'Set state on children',
  textDecoration: 'Text decoration',
  dummyData: 'Dummy data',
  propertyFields: 'Property fields',
  exportable: 'Exportable',
  filters: 'Filters',
  keyboardOptions: 'Keyboard options',
  mouseOptions: 'Mouse options',
  numberFormatter: 'Number formatter',
  rtl: 'Right-to-left',
  showSystemTooltip: 'Show system tooltip',
  states: 'States',
  tension: 'Tension',
  tooltip: 'Tooltip',
  waveHeight: 'Wave height',
  waveLength: 'Wave length',
  endValue: 'End value',
  tapTimeout: 'Tap timeout',
  background: 'Background',
  contentValign: 'Content vertical alignment',
  preloader: 'Preloader',
  tapToActivate: 'Tap to activate',
  ignoreMinMax: 'Ignore min/max',
  dataFields: 'Data fields',
  interpolationDuration: 'Interpolation duration',
  interpolationEasing: 'Interpolation easing',
  parsingStepDuration: 'Parsing step duration',
  rangeChangeDuration: 'Range change duration',
  rangeChangeEasing: 'Range change easing',
  sequencedInterpolation: 'Sequenced interpolation',
  sequencedInterpolationDelay: 'Sequenced interpolation delay',
  dataSource: 'Data source',
  end: 'End',
  maxZoomCount: 'Max. zoom count',
  maxZoomFactor: 'Max. zoom factor',
  minZoomCount: 'Min. zoom count',
  responsive: 'Responsive',
  start: 'Start',
  legendSettings: 'Legend settings',
  bullets: 'Bullets',
  cursorTooltipEnabled: 'Cursor tooltip enabled',
  xAxis: 'X axis',
  yAxis: 'Y axis',
  calculatePercent: 'Calculate percent',
  simplifiedProcessing: 'Simplified processing',
  skipFocusThreshold: 'Skip focus threshold',
  baseAxis: 'Base axis',
  cursorHoverEnabled: 'Cursor mouse-over enabled',
  excludeFromTotal: 'Exclude from total',
  hiddenInLegend: 'Hidden in legend',
  itemReaderText: 'Item reader text',
  minBulletDistance: 'Min. bullet distance',
  name: 'Name',
  snapTooltip: 'Snap tooltip',
  stacked: 'Stacked',
  titles: 'Titles',
  colors: 'Colors',
  dragGrip: 'Drag grip',
  innerRadius: 'Inner radius',
  legend: 'Legend',
  maskBullets: 'Mask bullets',
  radius: 'Radius',
  links: 'Links',
  east: 'East',
  exclude: 'Exclude',
  geodata: 'Geodata',
  geodataSource: 'Geodata source',
  ignoreBounds: 'Ignore bounds',
  include: 'Include',
  north: 'North',
  south: 'South',
  useGeodata: 'Use geodata',
  west: 'West',
  maxPanOut: 'Max. pan out',
  mouseWheelBehavior: 'Mouse wheel behavior',
  patterns: 'Patterns',
  series: 'Series',
  label: 'Label',
  arrangeTooltips: 'Arrange tooltips',
  cursor: 'Cursor',
  endAngle: 'End angle',
  scrollbarX: 'Horizontal scrollbar',
  scrollbarY: 'Vertical scrollbar',
  startAngle: 'Start angle',
  xAxes: 'X axes',
  yAxes: 'Y axes',
  zoomOutButton: 'Zoom out button',
  maxPosition: 'Max. position',
  minPosition: 'Min. position',
  position: 'Position',
  axisFill: 'Axis fill',
  grid: 'Grid',
  tick: 'Tick',
  value: 'Value',
  breakSize: 'Break size',
  endDate: 'End date',
  endLine: 'End line',
  fillShape: 'Fill shape',
  startLine: 'Start line',
  startValue: 'Start value',
  calculateTotals: 'Calculate totals',
  axisBreaks: 'Axis breaks',
  axisRanges: 'Axis ranges',
  endLocation: 'End location',
  extraMax: 'Extra maximum',
  extraMin: 'Extra minimum',
  includeRangesInMinMax: 'Include ranges in min/max',
  keepSelection: 'Keep selection',
  logarithmic: 'Logarithmic scale',
  max: 'Maximum',
  maxPrecision: 'Max. precision',
  min: 'Minimum',
  renderer: 'Renderer',
  startLocation: 'Start location',
  strictMinMax: 'Strict min/max',
  title: 'Title',
  toggleZoomOutButton: 'Toggle zoom out button',
  endCategory: 'End category',
  clustered: 'Clustered',
  columns: 'Columns',
  column: 'Column',
  data: 'Data',
  categoryX: 'X category field',
  categoryY: 'Y category field',
  dateX: 'X date field',
  dateY: 'Y date field',
  openCategoryX: 'X open category field',
  openCategoryY: 'Y open category field',
  openDateX: 'X open date field',
  openDateY: 'Y open date field',
  openValueX: 'X open value field',
  openValueXShow: 'X open value calculated field',
  openValueY: 'Y open value field',
  openValueYShow: 'Y open value calculated field',
  valueX: 'X value field',
  valueXShow: 'X value calculated field',
  valueY: 'Y value field',
  valueYShow: 'Y value calculated field',
  category: 'Category field',
  baseLineRatio: 'Baseline ratio',
  ellipsis: 'Ellipsis',
  fullWords: 'Full words',
  hideOversized: 'Hide oversized',
  html: 'HTML',
  ignoreFormatting: 'Ignore formatting',
  locationOnPath: 'Location on path',
  selectable: 'Selectable',
  text: 'Text',
  textAlign: 'Text alignment',
  truncate: 'Truncate',
  wrap: 'Wrap',
  backgroundFill: 'Background fill',
  backgroundOpacity: 'Background opacity',
  alignLabels: 'Align labels',
  labels: 'Labels',
  slices: 'Slices',
  ticks: 'Ticks',
  markers: 'Markers',
  orientation: 'Orientation',
  highLine: 'High line',
  lowLine: 'Low line',
  link: 'Link',
  middleLine: 'Middle line',
  colorMode: 'Color mode',
  gradient: 'Gradient',
  tooltipLocation: 'Tooltip location',
  nodes: 'Nodes',
  minNodeSize: 'Min. node size',
  nodePadding: 'Node padding',
  sortBy: 'Sort by',
  copyToLegendMarker: 'Copy to legend marker',
  isDynamic: 'Dynamic',
  locationX: 'X location',
  locationY: 'Y location',
  length: 'Length',
  angle: 'Angle',
  depth: 'Depth',
  bottomRatio: 'Bottom ratio',
  labelsOpposite: 'Labels opposite',
  icon: 'Icon',
  iconPosition: 'Icon position',
  brightnesses: 'Brightnesses',
  lightnesses: 'Lightnesses',
  offsets: 'Offsets',
  opacities: 'Opacities',
  minDistance: 'Min. distance',
  segments: 'Segments',
  autoGapCount: 'Auto gap count',
  connect: 'Connect',
  tensionX: 'X tension',
  tensionY: 'Y tension',
  x1: 'X1',
  x2: 'X2',
  y1: 'Y1',
  y2: 'Y2',
  updateWhileMoving: 'Update while moving',
  animationDuration: 'Animation duration',
  animationEasing: 'Animation easing',
  endGrip: 'End grip',
  hideGrips: 'Hide grips',
  startGrip: 'Start grip',
  thumb: 'Thumb',
  cornerRadius: 'Corner radius',
  innerCornerRadius: 'Inner corner radius',
  shiftRadius: 'Shift radius',
  controlPointDistance: 'Control point distance',
  pointerBaseWidth: 'Pointer base width',
  pointerLength: 'Pointer length',
  bottom: 'Bottom',
  classPrefix: 'Class prefix',
  defaultStyles: 'Default styles',
  left: 'Left',
  right: 'Right',
  showCurtain: 'Show curtain',
  top: 'Top',
  verticalAlign: 'Vertical alignment',
  color: 'Color',
  baseColor: 'Base color',
  saturation: 'Saturation',
  startIndex: 'Start index',
  bottomWidth: 'Bottom width',
  pyramidHeight: 'Pyramid height',
  topWidth: 'Top width',
  valueIs: 'Value is',
  radiusValue: 'Radius value',
  above: 'Above',
  location: 'Location',
  blur: 'Blur',
  radiusY: 'Y radius',
  arc: 'Arc',
  fromName: 'From name',
  toName: 'To name',
  children: 'Children',
  intlLocales: 'Internationalization locales',
  slice: 'Slice',
  sliceLinks: 'Slice links',
  baseUnit: 'Base unit',
  baseValue: 'Base value',
  behavior: 'Behavior',
  fullWidthLineX: 'Full width horizontal line',
  fullWidthLineY: 'Full width vertical line',
  hideSeriesTooltipsOnSelection: 'Hide series tooltips on selection',
  lineX: 'X line',
  lineY: 'Y line',
  selection: 'Selection',
  snapToSeries: 'Snap to series',
  dateFormat: 'Date format',
  durationFormat: 'Duration format',
  numberFormat: 'Number format',
  inside: 'Inside',
  baseGrid: 'Base grid',
  breakContainer: 'Break container',
  bulletsContainer: 'Bullets container',
  gridContainer: 'Grid container',
  line: 'Line',
  axisFills: 'Axis fills',
  cellEndLocation: 'Cell end location',
  cellStartLocation: 'Cell start location',
  fullWidthTooltip: 'Full width tooltip',
  inversed: 'Inversed',
  maxLabelPosition: 'Max. label position',
  minGridDistance: 'Min. grid distance',
  minLabelPosition: 'Min. label position',
  opposite: 'Opposite',
  endPosition: 'End position',
  startPosition: 'Start position'
};
